@use '@angular/material' as mat;
@include mat.core();
$NFCLive-primary: mat.define-palette(mat.$indigo-palette);
$NFCLive-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$NFCLive-warn: mat.define-palette(mat.$red-palette);
$NFCLive-theme: mat.define-light-theme((
  color: (
    primary: $NFCLive-primary,
    accent: $NFCLive-accent,
    warn: $NFCLive-warn,
  )
));
@include mat.all-component-themes($NFCLive-theme);

html,
body {
  height: 100%;
  font-size: 62.5%;
  font-family: 'Montserrat', sans-serif;
  ::-webkit-scrollbar {
    display: none;
    width: 0;
    height: 0;
    background: transparent;
}
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

.hide-scrollbar {
  ::-webkit-scrollbar {
      display: none;
      width: 0;
      height: 0;
      background: transparent;
  }
}

.modal .mat-mdc-dialog-container,  .mdc-dialog__container, .mdc-dialog__surface {
    border-radius: 25px !important;
}